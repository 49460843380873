<template>
  <v-container style="margin-top:60px; margin-bottom: 90px;" class="gray_bg_container"> 
      <v-container v-if="!linked||1">
         
             
              <v-img src="img/n_mob.png" style="margin-bottom: 15px;" max-width="100%" contain center></v-img>
              <!-- <center><v-img src="img/arch_cert_btn.png" max-width="100%" contain center @click="1"></v-img></center> -->
             
              <v-card elevation="10" class="rounded-card cert_desc" style="margin-bottom:35px;margin-bottom:20px;margin-top: 1px;border-radius: 15px;padding-left:10px;padding-top: 15px;padding-bottom: 15px;font-size: 22px;">
                Замовляй поповнення мобільного рахунку на суму від 100 грн. 

<br><br><font style="font-family: 'Inter';font-style: normal;font-weight: 400;text-decoration-line: underline; color: #F07300;"
>1 бал = 1 грн поповнення</font><br><br>

Бали буде переведено на вказаний Вами номер телефону.<br><br>
<font style="font-family: 'Inter';font-style: normal;font-weight: 400;text-decoration-line: underline; color: #F07300;"
>Уважно перевірте номер! Повернення коштів переведених на невірно вказаний номер телефону неможливе.</font>
   
        </v-card>

     <v-row fluid>
        
         <v-col cols="12">
           <v-text-field
           :class="{sinput:$i18n.locale=='ge'}"
             v-model="mob_send_num"
             name="mob_send_num"
             label="Номер поповнення"
             solo
             rounded
              v-maska="'+380 (##) ###-##-##'" 
             inputmode="numeric"
           ></v-text-field>
         </v-col>
        
       </v-row>

      <v-row fluid style="margin-top: -18px;">
          
          <v-col cols="12">
            <v-text-field
            :class="{sinput:$i18n.locale=='ge'}"
              v-model="mob_send"
              name="mob_send"
              label="Сума поповнення"
              solo
              rounded
              v-maska="'#*'" 
              inputmode="numeric"
            ></v-text-field>
          </v-col>
         
        </v-row>

         <v-row fluid style="margin-top: -18px;">
            <v-col cols="12">
              
                <v-img src="img/order_btn.png" max-width="100%" contain center @click="withdraw_mob()"></v-img>
            </v-col>
      </v-row>




      <v-card class="brand_card" style="margin-top: 25px;margin-bottom: 10px;">
      <v-row fluid style="margin-top: 25px;margin-bottom: 10px;">
            <v-col cols="1"></v-col>
            <v-col cols="10">
                <p style="text-align: center; margin-bottom: 0;">
              <b style="
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 24px;
/* or 109% */
text-align: center;

color: #4B3C32;

">{{$t('ordered_cert')}}:</b>
            </p>
            </v-col>
            <v-col cols="1"></v-col>
      </v-row>

     
<!-- 
       <v-container>
        <v-card  elevation="10"  class="brand_card"> -->

          <v-row fluid class="ma-0" style="" v-for="(order, i) in orders" :key="i"> 
            <v-col cols="12">
              <span class="cert_data">
              <font style="color:#F07300;">Номер:</font>&nbsp;&nbsp;<font style="font-weight: 400;">{{order['add_field']}}</font>
            </span><br>
            <span class="cert_data">
              <font style="color:#F07300;">Сума:</font>&nbsp;&nbsp; <font style="font-weight: 400;">{{order['amount']}} UAH</font>
            </span><br>
            <span class="cert_data mb-3">
                  <font style="color:#F07300;">Замовлено:</font>&nbsp;&nbsp; <font style="font-weight: 400;">{{order['a_date']}}</font>
                </span><br>
            <span>
              <b v-if="order['r_state']=='2'" style="color:red">Помилка</b>
                <b v-if="order['r_state']=='1'" style="color:green">Поповнено</b>
                <b v-if="order['r_state']=='9'||order['r_state']=='0'" style="color:orange">Очікуємо статус</b>


            </span><br><br>
             
            </v-col>
            
            <hr style="width: 95%;margin-left: 2.5%;">
                </v-row>
            <!-- </v-card>
          </v-container>    -->

    


  </v-card>




      <!-- <v-row fluid>
            <v-col cols="1"></v-col>
            <v-col cols="10">
                <p style="text-align: center;">
              <b>Замовлені поповнення:</b>
            </p>
            </v-col>
            <v-col cols="1"></v-col>
      </v-row>


      
     <v-container>
       <v-container v-for="(order, i) in orders" :key="i">
        <v-card  elevation="10">
          <v-row fluid>
            
              <v-col cols="7" style="display: flex;    align-items: center">
                  <p style="text-align: center;width:100%">
                <b>{{order['a_date']}}</b><br>{{order['add_field']}}
              </p>
              </v-col>
              <v-col cols="5">
                  <p style="text-align: center;">

                <b>{{order['amount']}} UAH</b><br>
                <b v-if="order['r_state']=='2'" style="color:red">Помилка</b>
                <b v-if="order['r_state']=='1'" style="color:green">Поповнено</b>
                <b v-if="order['r_state']=='9'||order['r_state']=='0'" style="color:orange">Очікуємо статус</b>
              </p>
              </v-col>
            
          </v-row>
           
        </v-card>
      </v-container>
      </v-container>  -->



      <v-dialog persistent v-model="sms_auth_dialog" style="">
      <v-card class="simple_card">
        <v-card-title class="headline lighten-2" style="margin-bottom:25px;justify-content:center;">
          <!-- header -->
          Введіть код з SMS
          <div @click="sms_auth_dialog = false" class="close_dialog"></div>
        </v-card-title>
       
        <v-card-text>
          <!-- content -->
          <v-form class="pass_reset" @submit.prevent="withdraw_sms_check">
        <v-row fluid  class="" style="margin-bottom: -35px;">
          
          <v-col cols="12">
            <v-text-field
              v-model="auth_code"
              name="auth_code"
              v-maska="'####'" 
              label="Введіть SMS-код"
              solo
              rounded
            ></v-text-field>
          </v-col>
          
        </v-row>
        <v-row fluid class="mt-0 pt-0">
          <v-col cols="12">
            <v-btn class="btn_new" type="submit" style=""
              >Продовжити</v-btn
            >
          </v-col>
        </v-row>
          </v-form>
          
        </v-card-text>
        
      </v-card>
     <div class="popup_bottom_robot"></div>
    </v-dialog>



          <v-overlay :value="isLoading">
      <v-progress-circular indeterminate
        color="red">
        
      </v-progress-circular>
      <br />
          </v-overlay>
    </v-container>
</v-container>
</template>
 
<script>
import btnrnd from "../components/btnrnd.vue";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { API_LOCATION } from "../../config";
export default {
  name: "Info",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    btnrnd,
  },
  data: () => ({
    sms_auth_dialog:false,
    auth_code:null,
    step_0: false,
    step_1: false,
    sms_e:false,
      date: new Date().toISOString().substr(0, 10),
    linked:true,
    fishka_num:0,
    mob_send:'',
    mob_send_num:'+380',
    amount:null,
    personal_number: null,
    oppa_account:null,
    orders:[],
    transaction_id:"",
    sms_verify_code:"",
    modal: false,
    menu2: false,
    isLoading:false,
    month_lang:{
       
        'ua':['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь']
      },
    
  }),
  computed: {
    task1: function () {
      return this.cards.filter((i) => i.type == "1");
    },
    task2: function () {
      return this.cards.filter((i) => i.type == "2");
    },
    task3: function () {
      return this.cards.filter((i) => i.type == "3");
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    authStatus: function () {
      return this.$store.getters.authStatus;
    },
    page_title: function () {
      return this.$store.getters.page_title;
    },
    fname: function () {
      return this.$store.getters.fname;
    },
    lname: function () {
      return this.$store.getters.lname;
    },
    points: function () {
      return this.$store.getters.points;
    },
    avatar: function () {
      return this.$store.getters.avatar;
    }, 
    
    title() {
        return this.$t('title')
      },

    date_locale(){
        if(this.$i18n.locale=='ru')
        {
            return 'ru-RU';
        }
        else
        {
            return 'ka-GE';
        }
    }
  },
  watch:{
      title: function(){
        this.$store.dispatch("page_title_set", this.title);
      }
  },
  mounted() {},
  created() {
    this.$store.dispatch("page_title_set", this.title);
    this.$store.dispatch("profileset");
    //this.fishka_get();
    this.get_mob();
    this.mob_send_num=this.$store.getters.msisdn;
  
   // this.get_rozetka();
  },
  methods:{
    
    withdraw_sms_check:function(){
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "check_sms_session_withdraw",
          auth_code:this.auth_code
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data); 
        if(resp.data['code']=='OK')
        {
        this.sms_auth_dialog=false; 
         this.withdraw_mob();
         
        }
        else if(resp.data['code']=='wrong')
        {
          alert(this.$t('wrong_code'));

        }
        
        this.isLoading=false;
        
      });

    }
    ,

    withdraw_mob: function () {
     
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "ks_order",
          ks_sum:this.mob_send,
          mob_send_num:this.mob_send_num,
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data); 
        if(resp.data['code']=='OK')
        {
          this.mob_send='';
         this.get_mob();
         alert(this.$t('order_success'));
        }
        else if(resp.data['code']=='no_session')
        {
          this.sms_auth_dialog=true;

        }
        else if(resp.data['code']=='insuf')
        {
          alert(this.$t('order_insuf'));

        }
        else if(resp.data['code']=='bad_msisdn')
        {
          alert(this.$t('bad_msisdn'));

        }
        else if(resp.data['code']=='insuf_min')
        {
          alert("Мінімальна сума - 100 грн.");

        }
        else if(resp.data['code']=='insuf_max')
        {
         alert(this.$t('insuf_max'));

        }
        else if(resp.data['code']=='insuf_max_op')
        {
         alert(this.$t('insuf_max_op'));

        }
        else if(resp.data['code']=='next_day_queue')
        {
         alert(this.$t('next_day_queue'));

        }
        else if(resp.data['code']=='next_day_try')
        {
         alert(this.$t('next_day_try'));

        }
        this.isLoading=false;
        //this.get_mob();
      });
      
    },
    get_mob: function () {
     
     this.isLoading=true;
     axios
     .post(
       API_LOCATION,
       {
         
         action: "get_mob_orders" 
         
       },
       { headers: { "content-type": "application/x-www-form-urlencoded" } }
     )
     .then((resp) => {
     
       console.log(resp.data); 
       this.orders=resp.data;
        this.isLoading=false;
       
     });
     
   },
    get_rozetka: function () {
     
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "get_rozetka_eps" 
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data); 
        this.orders=resp.data;
         this.isLoading=false;
        
      });
      
    },


   fishka_get: function () {
     
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "fishka_get" 
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
        
      if(resp.data)
      {
        this.fishka_num=resp.data;
        this.step_1=true;
      }
      else
      {
         this.step_0=true;
      }
      
        console.log(resp.data); 
         this.isLoading=false;
        
      });
      
    },
    
  }
};
</script>

<i18n>
{
  "ua":{
      "title": "Обмін балів",
      
      "cert_txt":"Для замовлення 1 сертифікату Rozetka номіналом 200 грн натисність «Замовити».  ",
      "btn_order":"Замовити",
      "cert_del_txt":"Для використання сертифікату покажіть його на касі перед покупкою у будь-якому вигляді: зображенням у iTeam, скріншотом або роздруківкою.",
      "order_success":"Поповнення замовлено",
      "order_insuf":"Недостатньо балів",
      "bad_msisdn":"Перевірте номер телефону",
      "ordered_cert":"Замовлені поповнення",
      "later_cert":"Сервіс замовлення сертифікатів тимчасово недоступний. Спробуйте пізніше.",
      "insuf_max":"Максимальна сума поповнення за добу складає 3000 грн.",
      "insuf_max_op":"Шановний користувач! Максимальна сума однієї транзакції на поповнення мобільного рахунку складає 1100 грн. При цьому обмеження на кількість транзакцій немає.",
      "next_day_queue":"Відповідно до обмежень НБУ, встановлених у військовий час, на сьогодні додаток перевищив добовий ліміт поповнень мобільних рахунків. Проте Ваш рахунок буде поповнено завтра одним із перших. Будь ласка, очікуйте на поповнення.",
      "next_day_try":"Відповідно до обмежень НБУ, встановлених у військовий час, на сьогодні додаток перевищив добовий ліміт поповнень мобільних рахунків. Будь ласка, спробуйте замовити поповнення Вашого рахунку завтра.",
      "wrong_code":"Невірний код"
      

      


      

  }
}
</i18n>